import { LegacyRef, SVGProps } from "react";

export const DoctorIcon = ({ ref, ...props }: SVGProps<SVGElement>) => {
  return (
    <svg
      {...props}
      ref={ref as LegacyRef<SVGSVGElement>}
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7 8C9.20938 8 11 6.20937 11 4C11 1.79063 9.20938 0 7 0C4.79063 0 3 1.79063 3 4C3 6.20937 4.79063 8 7 8ZM4 9.725C1.6875 10.4031 0 12.5406 0 15.0719C0 15.5844 0.415625 16 0.928125 16H13.0719C13.5844 16 14 15.5844 14 15.0719C14 12.5406 12.3125 10.4031 10 9.725V11.3125C10.8625 11.5344 11.5 12.3188 11.5 13.25V14.5C11.5 14.775 11.275 15 11 15H10.5C10.225 15 10 14.775 10 14.5C10 14.225 10.225 14 10.5 14V13.25C10.5 12.6969 10.0531 12.25 9.5 12.25C8.94687 12.25 8.5 12.6969 8.5 13.25V14C8.775 14 9 14.225 9 14.5C9 14.775 8.775 15 8.5 15H8C7.725 15 7.5 14.775 7.5 14.5V13.25C7.5 12.3188 8.1375 11.5344 9 11.3125V9.52812C8.8125 9.50937 8.62188 9.5 8.42813 9.5H5.57188C5.37813 9.5 5.1875 9.50937 5 9.52812V11.5719C5.72188 11.7875 6.25 12.4563 6.25 13.25C6.25 14.2156 5.46562 15 4.5 15C3.53437 15 2.75 14.2156 2.75 13.25C2.75 12.4563 3.27813 11.7875 4 11.5719V9.725ZM4.5 14C4.91563 14 5.25 13.6656 5.25 13.25C5.25 12.8344 4.91563 12.5 4.5 12.5C4.08437 12.5 3.75 12.8344 3.75 13.25C3.75 13.6656 4.08437 14 4.5 14Z" />
    </svg>
  );
};
